.App {
  overflow-x: hidden;
  padding-bottom: 70px; 
}


.react-bootstrap-table table {
  table-layout: auto !important;
}


.table {
  border: 0.5px solid #000000;
  text-align: center;
}
.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
  border: 0.5px solid #000000;
  text-align: center;
}

.clickableSpan {
  cursor: pointer;
}

.clickableSpan .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: auto;
  bottom: auto;
  left: auto;
  margin-left: 32px;
  
  /* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
  opacity: 0;
  transition: opacity 1s;
  margin-left: 32px;
}

.clickableSpan:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.sidebar-sticky ul{
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar .nav-link.active{
  background-color: #e8e8e8;
  color: black!important;
  border-radius: 10px;
  text-decoration: none;
}

.sidebar .nav-link:hover{
  background-color: #e8e8e8;
  color: black!important;
  border-radius: 10px;
  text-decoration: none;
}

.navbar{
  border-bottom: 1px solid #dddddd;
}
 /* .main-container {
  padding-left: 15rem!important;
 } */