body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.sidebar ul{
  list-style-type: none;
  padding: 5px;
}
.sidebar .nav-link {
  font-weight: 500;
  color: #333;
  text-align: left;
  padding-left: 10px;
}
.custom-css{
  overflow: scroll;
  margin-top: 65px;
  text-align: left;
}
.d-block-xxs{
  display: none;
}
@media only screen and (max-width: 576px) {
  .d-none-xxs{
    display: none;
  }
  .d-block-xxs{
    display: block;
  }
  .custom-css{
    overflow: scroll;
    margin-top: 15px;
    text-align: left;
  }
  .col-sm-3{
    width: 100%;
  }
  .dropdown-menu[data-bs-popper] {
    top: 100%;
    left: -110px !important;
    margin-top: var(--bs-dropdown-spacer);
}
}
@media only screen and (max-width: 700px) {
  .sidebar a {
    float: none !important;
}
}
@media only screen and (max-width: 768px) {
  .sidebar a {
    float: none !important;
}
}